var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('BaseForm',{ref:"formEl",attrs:{"model":_vm.form,"rules":_vm.rules}},[_c('fieldset',{},[_c('legend',{staticClass:"text-2xl font-bold"},[_vm._v("\n          "+_vm._s(_vm.$t('register.newAccount'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"font-light text-lg mb-4"},[_vm._v(_vm._s(_vm.$t('register.newAccountSub')))]),_vm._v(" "),_c('div',{staticClass:"space-y-5"},[_c('BaseFormItem',{attrs:{"prop":"gender","label":_vm.$t('userInfo.gender')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var status = ref.status;
var touch = ref.touch;
return [_c('div',{staticClass:"flex items-center lg:min-h-10"},[_c('BaseRadio',{staticClass:"min-w-24",attrs:{"id":"gender","variant":"primary","value":"Male","label":_vm.$t('userInfo.gender.Male'),"status":status},on:{"change":touch},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}}),_vm._v(" "),_c('BaseRadio',{staticClass:"min-w-24",attrs:{"variant":"primary","value":"Female","label":_vm.$t('userInfo.gender.Female'),"status":status},on:{"change":touch},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}}),_vm._v(" "),_c('BaseRadio',{staticClass:"min-w-24",attrs:{"variant":"primary","value":"Gender neutral","label":_vm.$t('userInfo.gender.Neutral'),"status":status},on:{"change":touch},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}})],1)]}}])}),_vm._v(" "),_c('BaseFormItem',{attrs:{"label":_vm.$t('userInfo.firstName'),"required":true}},[_c('div',{staticClass:"grid grid-cols-1 md:grid-cols-3 gap-2.5"},[_c('BaseFormItem',{attrs:{"prop":"firstName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var status = ref.status;
var touch = ref.touch;
return [_c('BaseInput',{attrs:{"id":"firstName","placeholder":_vm.$t('userInfo.firstName'),"status":status},on:{"change":touch},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})]}}])}),_vm._v(" "),_c('BaseInput',{attrs:{"placeholder":_vm.$t('userInfo.middleName')},model:{value:(_vm.form.middleName),callback:function ($$v) {_vm.$set(_vm.form, "middleName", $$v)},expression:"form.middleName"}}),_vm._v(" "),_c('BaseFormItem',{attrs:{"prop":"lastName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var status = ref.status;
var touch = ref.touch;
return [_c('BaseInput',{attrs:{"placeholder":_vm.$t('userInfo.lastName'),"status":status},on:{"change":touch},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})]}}])})],1)]),_vm._v(" "),_c('BaseFormItem',{attrs:{"prop":"email","label":_vm.$t('userInfo.email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var status = ref.status;
var touch = ref.touch;
return [_c('BaseInput',{attrs:{"id":"email","type":"email","placeholder":_vm.$t('userInfo.email'),"status":status},on:{"change":touch},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}])}),_vm._v(" "),_c('BaseFormItem',{attrs:{"prop":"password","label":_vm.$t('userInfo.password')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var status = ref.status;
var touch = ref.touch;
return [_c('BaseInput',{attrs:{"id":"password","type":"password","autocomplete":"new-password","placeholder":_vm.$t('userInfo.password'),"status":status},on:{"change":touch},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}])}),_vm._v(" "),_c('BaseFormItem',{attrs:{"prop":"confirmPassword","label":_vm.$t('userInfo.confirmPassword')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var status = ref.status;
var touch = ref.touch;
return [_c('BaseInput',{attrs:{"id":"confirmPassword","type":"password","autocomplete":"confirm-password","placeholder":_vm.$t('userInfo.confirmPassword'),"status":status},on:{"change":touch},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", $$v)},expression:"form.confirmPassword"}})]}}])}),_vm._v(" "),_c('BaseFormItem',{attrs:{"label-for":"language","label":_vm.$t('common.text.language')}},[_c('BaseSelect',{staticClass:"h-10 w-32",attrs:{"id":"language","options":_vm.languageCodeOptions},model:{value:(_vm.form.languageCode),callback:function ($$v) {_vm.$set(_vm.form, "languageCode", $$v)},expression:"form.languageCode"}})],1),_vm._v(" "),_c('div',{staticClass:"mb-2.5 lg:flex lg:items-start"},[_c('div',{staticClass:"lg:w-[130px] lg:flex lg:items-center lg:min-h-10 lg:pr-4 lg:justify-end"},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.$t('common.text.terms')))])]),_vm._v(" "),_c('div',{staticClass:"lg:flex-1"},[_c('p',{staticClass:"mb-3"},[_c('BaseCheckbox',{model:{value:(_vm.form.isOptIn),callback:function ($$v) {_vm.$set(_vm.form, "isOptIn", $$v)},expression:"form.isOptIn"}},[_c('span',{staticClass:"opacity-60"},[_vm._v("\n                    "+_vm._s(_vm.$t('common.text.newMsg'))+"\n                  ")])])],1),_vm._v(" "),_c('p',[_c('BaseCheckbox',{model:{value:(_vm.form.acceptTermsConditions),callback:function ($$v) {_vm.$set(_vm.form, "acceptTermsConditions", $$v)},expression:"form.acceptTermsConditions"}},[_c('span',{staticClass:"opacity-60",domProps:{"innerHTML":_vm._s(
                      _vm.$t('common.text.agreement', {
                        link: '/algemene-voorwaarden',
                      })
                    )}})])],1)])])],1)])]),_vm._v(" "),_c('AppNoty',{staticClass:"mt-5 w-full",attrs:{"show":_vm.isRegisterFail,"text":_vm.registerFailMessage,"status":"error"},on:{"update:show":function($event){_vm.isRegisterFail=$event}}}),_vm._v(" "),_c('div',{staticClass:"pt-5"},[_c('BaseButton',{attrs:{"disabled":_vm.registerLoading || !_vm.form.acceptTermsConditions,"loading":_vm.registerLoading},on:{"click":_vm.onSubmit}},[_c('span',{staticClass:"block"},[_vm._v(_vm._s(_vm.$t('common.text.register')))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }